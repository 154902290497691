import React, {useState, useEffect} from 'react';
import {marked} from 'marked';

function Dictionnaire() {
    const [mots, setMots] = useState([]);
    const [selectedLetter, setSelectedLetter] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const response = await fetchMots();
            setMots(response);
        }

        fetchData();
    }, [fetchMots]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function fetchMots() {
        const files = await importAll(
            require.context('../dictionnary', false, /\.md$/)
        );
        return await Promise.all(
            files.map(async file => {
                const motName = file.split('/').pop().split('.')[0];
                const response = await fetch(file);
                const content = await response.text();
                return {
                    mot: motName,
                    definition: marked.parse(content)
                };
            })
        );
    }

    function importAll(r) {
        return r.keys().map(r);
    }

    const filteredMots = selectedLetter ? mots.filter(mot => mot.mot.startsWith(selectedLetter)) : mots;

    return (
        <div className="container px-4 mx-auto mt-8">
            <div className="flex items-center font-light gap-4 mb-4">
                <div>Dictionnaire</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                    </svg>
                </div>
                <div>Abécédaire</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
                    </svg>
                </div>
                Définitions {selectedLetter}
            </div>
            <div className="flex flex-wrap gap-2 mb-4">
                <button
                    className={`px-3 py-2 border font-bold hover:bg-gray-200 ${
                        selectedLetter === null ? 'bg-gray-200' : ''
                    }`}
                    onClick={() => setSelectedLetter(null)}
                >
                    TOUS
                </button>

                {Array.from(Array(26).keys()).map(i => (
                    <button
                        key={i}
                        className={`px-3 py-2 border font-bold hover:bg-gray-200 ${
                            selectedLetter === String.fromCharCode(65 + i) ? 'bg-gray-200' : ''
                        }`}
                        onClick={() => setSelectedLetter(String.fromCharCode(65 + i))}
                    >
                        {String.fromCharCode(65 + i)}
                    </button>
                ))}
            </div>
            {filteredMots.map((mot, index) => (
                <div key={index} className="my-4">
                    <h2 className="text-2xl font-bold mb-2">{mot.mot}</h2>
                    <div className="text-justify" dangerouslySetInnerHTML={{__html: mot.definition}}/>
                </div>
            ))}
        </div>
    );
}

export default Dictionnaire;
