import React from 'react';
import Dictionnaire from './components/Dictionnaire';

function App() {
    return (
        <div className="bg-slate-50 min-h-screen">
            <header className="bg-orange-300 text-white py-4">
                <div className="px-4 container mx-auto">
                    <h1 className="text-2xl flex items-center gap-2 font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                        </svg>
                        Thierryctionnaire
                    </h1>
                    <div>
                        par <a href="https://maved.fr" rel="noreferrer" target="_blank"
                               className="transition underline underline-offset-4 font-bold">Mavrick</a>
                    </div>
                </div>
            </header>

            <Dictionnaire/>

        </div>
    );
}

export default App;